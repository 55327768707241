import _ from "lodash";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.closeModal, "title": "Edit Cluster", "width": 520 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.closeModal } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isSaving }, on: { "click": function($event) {
        return handleSubmit(_vm.onSaveClick);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 24 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Cluster", "value": _vm.editForm.clusterName, "max-length": 50, "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("clusterName", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Owner ID", "value": _vm.editForm.ownerId, "max-length": 8, "rules": "required" }, on: { "change": _vm.onOwnerIdChange } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Owner Name", "value": _vm.editForm.ownerName, "rules": "required", "loading": _vm.isFetchingOwner, "disabled": true }, on: { "change": function($event) {
      return _vm.storeValue("ownerName", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditClusterOwner",
  inject: ["apiUrl", "crud", "redirectRoute"],
  data() {
    return {
      visible: true,
      editForm: {},
      isFetchingOwner: false,
      isSaving: false,
      user: {}
    };
  },
  watch: {
    user(newVal) {
      const { firstName, lastName } = newVal;
      const ownerName = firstName && lastName ? `${firstName} ${lastName}` : "";
      this.storeValue("ownerName", ownerName);
      this.editForm = {
        ...this.editForm,
        ownerName
      };
    }
  },
  mounted() {
    const { id } = this.$route.params;
    this.editForm = this.crud.getEntity(id);
    this.storeValues();
  },
  methods: {
    onOwnerIdChange: _.debounce(function(value) {
      this.isFetchingOwner = true;
      this.storeValue("ownerId", value);
      this.axios.get(this.apiUrl + `/identities/ad/users/${value}`).then((res) => this.user = res.data).catch((err) => {
        this.user = {};
        value && this.showErrorMsg(err);
      }).finally(() => this.isFetchingOwner = false);
    }, 500),
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", { resourceKey, value });
    },
    storeValues() {
      Object.entries(this.editForm).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    onSaveClick() {
      this.isSaving = true;
      this.crud.submitEntity("replace").then(() => {
        this.crud.fetchList();
        this.visible = false;
      }).finally(() => this.isSaving = false);
    },
    showErrorMsg(err) {
      this.$notification["error"]({
        message: err.response.data.message || "Errors encountered!"
      });
    },
    closeModal() {
      this.$router.push(this.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditClusterOwner = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-cluster-owner" }, [_c("resource", { attrs: { "name": "identities.users", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.clusters", "api-url": _vm.apiUrl, "edit": _vm.EditClusterOwner, "redirect-route": "/web-analytics/cluster-owner" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditClusterOwner",
  data() {
    return {
      EditClusterOwner,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
